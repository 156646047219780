import * as R from 'ramda';
import { useParams } from 'react-router-dom';

import {
  RoboAdviceAdviceSessionPages,
  RoboAdviceOrderExecutionPages,
  SessionStatuses
} from '../../../shared/constants';
import { CUSTOM_PORTFOLIO_PRECISION } from '../../advisory/components/customPortfolio/constants';
import {
  calculateInternalPortfolioTotalValue,
  calculateMonthlySurplus
} from '../../advisory/services/mapping';
import { NamespaceStatus } from '../../advisory/types';
import {
  useForm as useRoboAdviceForm,
  getAdvisoryAdvisorNotesValidationError
} from '../../form/services/form';
import { advancedSuitabilityTables } from '../../knowledgeAndExperience/constants';
import { useKnowledgeAndExperienceStore } from '../../knowledgeAndExperience/services/knowledgeAndExperienceStore';
import { usePageStore as useProposalPageStore } from '../../proposal/services/pageStore';
import { useSessionStore } from '../../session/services/sessionStore';
import { Goal, useGoalsStore } from '../../shared/services/goalsStore';
import {
  calculateFinancialSituationProgress,
  calculateCustomFieldsTabProgress
} from '../../shared/services/progressSelectors.js';
import { getAlignmentCriteriaFields } from '../../sustainability/components/utils';
import { useReadProductChooserRecommendation } from 'features/roboAdvice/adviceSession/advisory/components/useReadProductChooserRecommendation';
import {
  getDetalizedNumberInputsTotalResult,
  useAmountForAdviceValues
} from 'features/roboAdvice/adviceSession/financialSituation/services/selectors';
import { useIsFollowUpEnabled } from 'features/roboAdvice/shared/components/useIsFollowUpEnabled';
import {
  AdviceSessionParams,
  ClientTypes
} from 'features/shared/constants/session';
import { CustomerConfigType } from 'features/shared/services/session/types';
import { filterNil } from 'features/shared/utils/filters';
import { roundNumber } from 'features/shared/utils/number';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { useI18n } from 'features/sharedModules/customerConfig/components/useI18n.js';
import { useGetAdviceInformationErrors } from 'features/sharedModules/errors/services/useGetAdviceInformationErrors';
import { useGetAdvisoryErrors } from 'features/sharedModules/errors/services/useGetAdvisoryErrors';
import { useGetFinancialSituationErrors } from 'features/sharedModules/errors/services/useGetFinancialSituationErrors';
import { useGetKnowledgeAndExperienceErrors } from 'features/sharedModules/errors/services/useGetKnowledgeAndExperienceErrors';
import { useGetPurposeAndRiskErrors } from 'features/sharedModules/errors/services/useGetPurposeAndRiskErrors';
import { useGetSustainabilityErrors } from 'features/sharedModules/errors/services/useGetSustainabilityErrors';
import { getNumberOfErrors } from 'features/sharedModules/errors/utils';

export type PageId =
  | typeof RoboAdviceAdviceSessionPages[keyof typeof RoboAdviceAdviceSessionPages]
  | typeof RoboAdviceOrderExecutionPages[keyof typeof RoboAdviceOrderExecutionPages];

export type Tab = {
  tab?: {
    value: PageId;
    progress: number;
    label: string;
    disabled: boolean;
    hasError: boolean;
  } | null;
  order?: number;
  enabled?: boolean;
  pageId: PageId;
};
export const getFieldsProgress = (fieldValidationResults: unknown[]) => {
  const positiveFieldValidationResults = R.filter(
    R.identity<any>,
    fieldValidationResults
  );

  return (
    R.length(positiveFieldValidationResults) / R.length(fieldValidationResults)
  );
};

export const useAdviceInformationTab = () => {
  const i18n = useI18n();
  const { values, errors: formErrors } = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isReadPdfReportPending = useProposalPageStore(
    s => s.isReadPdfReportPending
  );
  const {
    roboAdviceForm: {
      adviceInformation: { fields: adviceInformationFields }
    }
  } = useCustomerConfig();
  const errors = useGetAdviceInformationErrors();

  return getAdviceInformationTabResult({
    i18n,
    values,
    formErrors,
    adviceInformationFields,
    isSessionInitialized,
    isReadPdfReportPending,
    errors
  });
};

export const getAdviceInformationTabResult = ({
  i18n,
  values,
  formErrors,
  adviceInformationFields,
  isSessionInitialized,
  isReadPdfReportPending,
  errors
}) => {
  return {
    value: RoboAdviceAdviceSessionPages.adviceInformation,
    progress: calculateCustomFieldsTabProgress({
      fields: adviceInformationFields,
      values,
      errors: formErrors
    }),
    label: i18n('roboAdvice.tabs.adviceInformation'),
    disabled: isSessionInitialized && isReadPdfReportPending,
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const usePurposeAndRiskTab = () => {
  const i18n = useI18n();
  const {
    roboAdviceForm: {
      riskQuestions: { isRisk2Hidden }
    }
  } = useCustomerConfig();
  const roboAdviceFormState = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isReadPdfReportPending = useProposalPageStore(
    s => s.isReadPdfReportPending
  );
  const { goals } = useGoalsStore();
  const errors = useGetPurposeAndRiskErrors();

  return getPurposeAndRiskTabResult({
    i18n,
    roboAdviceFormState,
    isRisk2Hidden,
    isSessionInitialized,
    isReadPdfReportPending,
    goals,
    errors
  });
};

export const getPurposeAndRiskTabResult = ({
  i18n,
  roboAdviceFormState,
  isRisk2Hidden,
  isSessionInitialized,
  isReadPdfReportPending,
  goals,
  errors
}) => {
  const { errors: formErrors } = roboAdviceFormState;

  const fields = [R.isNil(formErrors.expectationOfRisk), !!goals.length];

  if (!isRisk2Hidden) {
    fields.push(R.isNil(formErrors.riskStrategy));
  }

  return {
    value: RoboAdviceAdviceSessionPages.purposeAndRisk,
    progress: getFieldsProgress(fields),
    label: i18n('roboAdvice.tabs.purposeAndRisk'),
    disabled: isSessionInitialized && isReadPdfReportPending,
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const useKnowledgeAndExperienceTab = () => {
  const i18n = useI18n();
  const {
    roboAdviceForm: { knowledgeAndExperienceQuestions }
  } = useCustomerConfig();
  const roboAdviceFormState = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isReadPdfReportPending = useProposalPageStore(
    s => s.isReadPdfReportPending
  );
  const {
    roboAdviceForm: {
      knowledgeAndExperience: {
        advancedSuitability: advancedSuitabilityCustomerConfig
      }
    }
  } = useCustomerConfig();
  const { advancedSuitabilityConfig } = useKnowledgeAndExperienceStore();
  const errors = useGetKnowledgeAndExperienceErrors();

  return getKnowledgeAndExperienceTabResult({
    i18n,
    roboAdviceFormState,
    knowledgeAndExperienceQuestions,
    isSessionInitialized,
    isReadPdfReportPending,
    advancedSuitabilityCustomerConfig,
    advancedSuitabilityConfig,
    errors
  });
};

export const getKnowledgeAndExperienceTabResult = ({
  i18n,
  roboAdviceFormState,
  knowledgeAndExperienceQuestions,
  isSessionInitialized,
  isReadPdfReportPending,
  advancedSuitabilityCustomerConfig,
  advancedSuitabilityConfig,
  errors
}) => {
  const { errors: formErrors, values } = roboAdviceFormState;

  let fields = knowledgeAndExperienceQuestions.questions.map(({ name }) =>
    R.isNil(formErrors[name])
  );

  if (advancedSuitabilityCustomerConfig.enabled) {
    const suitabilityFieldsNumber =
      advancedSuitabilityTables.filter(
        ({ name }) => advancedSuitabilityCustomerConfig[name].enabled
      ).length * advancedSuitabilityConfig?.checks?.length || 1;
    const filledSuitabilityFields = values.suitability
      ? Object.values(
          values.suitability as Record<string, Record<string, any>>
        ).reduce((acc, curr) => acc + Object.values(curr).length, 0)
      : 0;

    fields = fields.concat(
      Array(suitabilityFieldsNumber)
        .fill(true, 0, filledSuitabilityFields)
        .fill(false, filledSuitabilityFields)
    );
  }

  return {
    value: RoboAdviceAdviceSessionPages.knowledgeAndExperience,
    progress: getFieldsProgress(fields),
    label: i18n('roboAdvice.tabs.knowledgeAndExperience'),
    disabled: isSessionInitialized && isReadPdfReportPending,
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const useFinancialSituationTab = () => {
  const i18n = useI18n();
  const roboAdviceFormState = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isReadPdfReportPending = useProposalPageStore(
    s => s.isReadPdfReportPending
  );
  const {
    roboAdviceForm: { financialSituation: financialSituationConfig },
    advisoryComponents: {
      financialSituation: {
        amountForAdvice: { advisorNotes: amountForAdviceAdvisorNotesConfig }
      }
    }
  } = useCustomerConfig();
  const { currentSum: amountForAdviceCurrentSum } = useAmountForAdviceValues();
  const errors = useGetFinancialSituationErrors();
  const sessionStore = useSessionStore();
  const { isFollowUpEnabled } = useIsFollowUpEnabled();

  return getFinancialSituationTabResult({
    i18n,
    roboAdviceFormState,
    amountForAdviceCurrentSum,
    financialSituationConfig,
    amountForAdviceAdvisorNotesConfig,
    isSessionInitialized,
    isReadPdfReportPending,
    errors,
    isFollowUpFlow: !!sessionStore.followUpId && isFollowUpEnabled
  });
};

export const getFinancialSituationTabResult = ({
  i18n,
  roboAdviceFormState,
  amountForAdviceCurrentSum,
  isSessionInitialized,
  financialSituationConfig,
  amountForAdviceAdvisorNotesConfig,
  isReadPdfReportPending,
  errors,
  isFollowUpFlow
}) => {
  const { errors: formErrors, values } = roboAdviceFormState;
  const clientType = values?.clientInformation?.clientType;

  return {
    value: RoboAdviceAdviceSessionPages.financialSituation,
    progress: calculateFinancialSituationProgress({
      formErrors,
      values,
      amountForAdviceCurrentSum,
      financialSituationConfig,
      amountForAdviceAdvisorNotesConfig,
      clientType,
      errors,
      isFollowUpFlow
    }),
    label: i18n('roboAdvice.tabs.financialSituation'),
    disabled: isSessionInitialized && isReadPdfReportPending,
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const useSustainabilityTab = () => {
  const i18n = useI18n();
  const customerConfig = useCustomerConfig();
  const isSustainabilityEnabled =
    customerConfig?.roboAdvice?.sustainability?.enabled;
  const roboAdviceFormState = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isReadPdfReportPending = useProposalPageStore(
    s => s.isReadPdfReportPending
  );
  const errors = useGetSustainabilityErrors();

  if (!isSustainabilityEnabled) {
    return null;
  }

  return getSustainabilityTabResult({
    i18n,
    roboAdviceFormState,
    isSessionInitialized,
    isReadPdfReportPending,
    customerConfig,
    errors
  });
};

export const getSustainabilityTabResult = ({
  i18n,
  isSessionInitialized,
  isReadPdfReportPending,
  customerConfig,
  roboAdviceFormState,
  errors
}) => {
  const { values, errors: formErrors } = roboAdviceFormState;
  const genericAssessmentAnswer =
    values?.sustainability?.genericAssessment?.answer;
  const genericAssessmentComment =
    values?.sustainability?.genericAssessment?.comment;
  const preferenceCriteriaAdvisorNote =
    values?.sustainability?.preferenceCriteriaAdvisorNotes;
  const alignmentCriteriaFields = getAlignmentCriteriaFields({
    customerConfig
  });
  const requiredAdvisorNotesFields = alignmentCriteriaFields.filter(
    ({ config: { required } }) => required
  );

  const {
    genericAssessment: {
      enabled: isGenericAssessmentEnabled,
      isCommentFieldRequired,
      isCommentFieldShown
    } = {},
    sustainabilityPreference: {
      advisorNotes: {
        enabled: isPreferenceNoteEnabled,
        required: isPreferenceNoteRequired
      } = {}
    },
    offModelQuestions
  } = (customerConfig as CustomerConfigType)?.roboAdvice?.sustainability;

  const requiredOffModelQuestionsFields = offModelQuestions?.questions?.filter(
    question => question.enabled && question.required
  );
  const requiredOffModelQuestionsAdvisorNotes =
    offModelQuestions?.questions?.filter(
      question =>
        question.advisorNotes.enabled && question.advisorNotes.required
    );

  let progress = 0;
  let maxProgress = 0;

  if (isGenericAssessmentEnabled) {
    if (genericAssessmentAnswer === false) {
      progress += 1;
      maxProgress += 1;
    } else if (genericAssessmentAnswer === true) {
      progress += alignmentCriteriaFields.reduce(
        (prev, curr) =>
          R.view(R.lensPath(curr.name.split('.')), formErrors) ||
          R.isNil(R.view(R.lensPath(curr.name.split('.')), values))
            ? prev
            : prev + 1,
        0
      );
      progress += requiredAdvisorNotesFields.reduce(
        (prev, curr) =>
          R.view(R.lensPath(curr.textFieldName.split('.')), formErrors) ||
          R.isNil(R.view(R.lensPath(curr.textFieldName.split('.')), values)) ||
          R.view(R.lensPath(curr.textFieldName.split('.')), values) === ''
            ? prev
            : prev + 1,
        0
      );
      if (isPreferenceNoteEnabled && isPreferenceNoteRequired) {
        maxProgress += 1;
        if (preferenceCriteriaAdvisorNote?.length) {
          progress += 1;
        }
      }

      maxProgress +=
        alignmentCriteriaFields?.length + requiredAdvisorNotesFields?.length;

      if (
        offModelQuestions?.enabled &&
        (requiredOffModelQuestionsFields?.length ||
          requiredOffModelQuestionsAdvisorNotes?.length)
      ) {
        maxProgress +=
          (requiredOffModelQuestionsFields?.length ?? 0) +
          (requiredOffModelQuestionsAdvisorNotes?.length ?? 0);

        requiredOffModelQuestionsFields.forEach(({ name }) => {
          if (!errors?.[name]?.length) {
            progress += 1;
          }
        });

        requiredOffModelQuestionsAdvisorNotes.forEach(({ name }) => {
          if (!errors?.[`offModelQuestionsAdvisorNotes-${name}`]?.length) {
            progress += 1;
          }
        });
      }
    } else {
      maxProgress += 1;
    }
  } else {
    if (isPreferenceNoteEnabled && isPreferenceNoteRequired) {
      maxProgress += 1;
      if (preferenceCriteriaAdvisorNote?.length) {
        progress += 1;
      }
    }

    progress += alignmentCriteriaFields.reduce(
      (prev, curr) =>
        R.view(R.lensPath(curr.name.split('.')), formErrors) ||
        R.isNil(R.view(R.lensPath(curr.name.split('.')), values))
          ? prev
          : prev + 1,
      0
    );
    progress += requiredAdvisorNotesFields.reduce(
      (prev, curr) =>
        R.view(R.lensPath(curr.textFieldName.split('.')), formErrors) ||
        R.isNil(R.view(R.lensPath(curr.textFieldName.split('.')), values)) ||
        R.view(R.lensPath(curr.textFieldName.split('.')), values) === ''
          ? prev
          : prev + 1,
      0
    );

    maxProgress +=
      alignmentCriteriaFields?.length + requiredAdvisorNotesFields?.length;

    if (
      offModelQuestions?.enabled &&
      (requiredOffModelQuestionsFields?.length ||
        requiredOffModelQuestionsAdvisorNotes?.length)
    ) {
      maxProgress +=
        (requiredOffModelQuestionsFields?.length ?? 0) +
        (requiredOffModelQuestionsAdvisorNotes?.length ?? 0);

      requiredOffModelQuestionsFields.forEach(({ name }) => {
        if (!errors?.[name]?.length) {
          progress += 1;
        }
      });

      requiredOffModelQuestionsAdvisorNotes.forEach(({ name }) => {
        if (!errors?.[`offModelQuestionsAdvisorNotes-${name}`]?.length) {
          progress += 1;
        }
      });
    }
  }

  if (
    isGenericAssessmentEnabled &&
    isCommentFieldShown &&
    isCommentFieldRequired
  ) {
    maxProgress += 1;
    if (genericAssessmentComment?.length) {
      progress += 1;
    }
  }

  const offModelValueAdaptedErrors = offModelQuestions?.questions
    .map(({ name }) => errors?.[`${name}ValueAdapted`])
    .flat()
    .filter(filterNil);

  const valueAdaptedErrors = [
    ...(errors?.genericAssessmentValueAdapted || []),
    ...(errors?.preferenceCriteriaValueAdapted || []),
    ...(errors?.alignmentCriteria1ValueAdapted || []),
    ...(errors?.alignmentCriteria2ValueAdapted || []),
    ...(errors?.alignmentCriteria3ValueAdapted || []),
    ...(errors?.alignmentCriteria4ValueAdapted || []),
    ...(errors?.alignmentCriteria5ValueAdapted || []),
    ...(offModelValueAdaptedErrors || [])
  ];
  if (valueAdaptedErrors?.length) {
    maxProgress += 1;
  }

  return {
    value: RoboAdviceAdviceSessionPages.sustainability,
    progress: maxProgress > 0 ? progress / maxProgress : 1,
    label: i18n('roboAdvice.tabs.sustainability'),
    disabled: isSessionInitialized && isReadPdfReportPending,
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const useAdvisoryTab = () => {
  const i18n = useI18n();
  const { pageId, clientType } = useParams<AdviceSessionParams>();
  const {
    advisoryComponents,
    roboAdvice,
    roboAdviceForm: {
      advisory: { validateIfSavingPlanSuitable },
      financialSituation: { assets, accounts }
    },
    analyticsComponents: { isTransactionListHidden }
  } = useCustomerConfig();
  const roboAdviceFormState = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isFirstPartCompleted = useIsFirstPartCompleted();
  const isReadPdfReportPending = useProposalPageStore(
    s => s.isReadPdfReportPending
  );
  const { goals } = useGoalsStore();
  const readProductChooserRecommendation =
    useReadProductChooserRecommendation();
  const recommendationItems: {
    items: ReturnType<typeof readProductChooserRecommendation>['items'];
    goalId: string;
  }[] = [];
  for (const goal of goals) {
    const { items } = readProductChooserRecommendation({ goalId: goal.goalId });
    recommendationItems.push({ items, goalId: goal.goalId });
  }
  const errors = useGetAdvisoryErrors();
  const sessionStore = useSessionStore();
  const { isFollowUpEnabled } = useIsFollowUpEnabled();
  const { currentSum: amountForAdviceCurrentSum } = useAmountForAdviceValues();

  return getAdvisoryTabResult({
    i18n,
    roboAdviceFormState,
    advisoryComponents,
    validateIfSavingPlanSuitable,
    isSessionInitialized,
    isFirstPartCompleted,
    isReadPdfReportPending,
    pageId,
    goals,
    assets,
    accounts,
    isTransactionListHidden,
    clientType,
    roboAdvice,
    recommendationItems,
    errors,
    isFollowUpFlow: !!sessionStore.followUpId && isFollowUpEnabled,
    amountForAdviceCurrentSum
  });
};

export const getSavingsPlanValidationError = ({
  goals,
  validateIfSavingPlanSuitable,
  values,
  errors,
  assets,
  clientType,
  accounts,
  isTransactionListHidden,
  isFollowUpFlow,
  amountForAdviceCurrentSum
}) => {
  let savingsPlanError =
    goals.length > 0 &&
    goals.every(
      ({ data: { firstDeposit, monthlyDeposit, internalHolding } }) =>
        firstDeposit > 0 || monthlyDeposit > 0 || internalHolding > 0
    )
      ? null
      : 'roboAdvice.advisory.savingsPlan.noValuesError';

  if (!savingsPlanError && validateIfSavingPlanSuitable) {
    const firstDepositSum = goals.reduce((acc, goal) => {
      return acc + (goal.data.firstDeposit || 0);
    }, 0);

    const monthlyDepositSum = goals.reduce((acc, goal) => {
      return acc + (goal.data.monthlyDeposit || 0);
    }, 0);

    const internalHoldingSum = goals.reduce(
      (acc, goal) => acc + (goal.data.internalHolding || 0),
      0
    );

    const followUpAddOnSum = goals.reduce((acc, goal) => {
      return acc + (goal.data.followUpAddOn || 0);
    }, 0);

    const assetsFieldsName = assets[clientType]
      .filter(({ enabled }) => enabled)
      .map(({ id }) =>
        clientType === ClientTypes.person
          ? `personFinancialSituation.${id}`
          : `companyFinancialSituation.${id}`
      );
    const totalAssets =
      getDetalizedNumberInputsTotalResult({
        fields: assetsFieldsName,
        formState: { values, errors }
      }) || 0;

    const financialSituationValues =
      clientType === ClientTypes.person
        ? values.personFinancialSituation
        : values.companyFinancialSituation;

    const monthlySurplus = calculateMonthlySurplus(
      financialSituationValues?.monthlySurplus
    );

    const internalPortfolioTotalValue = !isTransactionListHidden
      ? calculateInternalPortfolioTotalValue({
          financialSituationValues,
          clientType,
          accountsSyncPersonMapping: accounts.sync.mapping.person,
          accountsSyncCompanyMapping: accounts.sync.mapping.company
        })
      : 0;

    const addOnLimit = amountForAdviceCurrentSum
      ? amountForAdviceCurrentSum - (internalPortfolioTotalValue ?? 0)
      : 0;

    if (
      (firstDepositSum > totalAssets && !isFollowUpFlow) ||
      (followUpAddOnSum > addOnLimit && isFollowUpFlow) ||
      (!R.isNil(monthlySurplus) && monthlyDepositSum > monthlySurplus) ||
      (!isTransactionListHidden &&
        !R.isNil(internalPortfolioTotalValue) &&
        internalPortfolioTotalValue !== internalHoldingSum) ||
      (isFollowUpFlow &&
        goals.some(
          goal =>
            (goal.data.followUpWithdrawal ?? 0) >
            (goal.data.internalHolding ?? 0)
        ))
    ) {
      savingsPlanError =
        'roboAdvice.advisory.savingsPlan.notSuitableSavingPlanError';
    }
  }
  return savingsPlanError;
};

export const getPortfolioValidationErrors = goals => {
  const errors: (string | [string, { [textToReplace: string]: string }])[] = [];
  for (const goal of goals) {
    errors.push(...getGoalPortfolioValidationErrors(goal));
  }

  if (goals.length === 0) {
    errors.push('roboAdvice.advisory.portfolio.portfolioEmptyValidationError');
  }

  return errors;
};

export const getGoalPortfolioValidationErrors = (goal: Goal) => {
  const errors: (string | [string, { [textToReplace: string]: string }])[] = [];
  if (!goal.data?.isPortfolioCustom && R.isNil(goal?.data?.portfolio)) {
    errors.push('roboAdvice.advisory.portfolio.portfolioEmptyValidationError');
  }

  if (goal.data?.isPortfolioCustom) {
    if (
      R.isNil(goal.data?.customPortfolio) ||
      R.isEmpty(goal.data?.customPortfolio)
    ) {
      errors.push(
        'roboAdvice.advisory.portfolio.customPortfolioEmptyValidationError'
      );
    } else {
      const assetsWeightSum = roundNumber(
        goal.data.customPortfolio.reduce(
          (prev, curr) => prev + (curr.weight ?? 0),
          0
        ),
        CUSTOM_PORTFOLIO_PRECISION
      );
      const isAssetsWeightSumCorrect = assetsWeightSum === 100;
      const isFundsWeightSumCorrect = goal.data.customPortfolio.every(asset => {
        const sum = roundNumber(
          asset.instruments.reduce(
            (prev, curr) => prev + (curr.weight ?? 0),
            0
          ),
          CUSTOM_PORTFOLIO_PRECISION
        );

        return sum === asset.weight || sum === 0;
      });
      const fundsWeightOverallSum = roundNumber(
        goal.data.customPortfolio.reduce((prev, asset) => {
          const sum = asset.instruments.reduce(
            (prev, curr) => prev + (curr.weight ?? 0),
            0
          );

          return prev + sum;
        }, 0),
        CUSTOM_PORTFOLIO_PRECISION
      );
      const isFundsWeightOverallSumCorrect = fundsWeightOverallSum === 100;

      if (!isAssetsWeightSumCorrect) {
        errors.push('roboAdvice.errors.assetAllocationAlert');
      }

      if (!isFundsWeightSumCorrect || !isFundsWeightOverallSumCorrect) {
        errors.push('roboAdvice.errors.fundAllocationAlert');
      }

      const doesAllAssetsHaveCorrectIncrementalSteps =
        !goal.data.customPortfolio.some(asset => {
          const weight = asset.weight ?? 0;
          return roundNumber(weight, CUSTOM_PORTFOLIO_PRECISION) !== weight;
        });

      if (!doesAllAssetsHaveCorrectIncrementalSteps) {
        errors.push([
          'roboAdvice.advisory.customPortfolio.changeAllocationMethod.errorInvalidIncrementalStepInPercentage',
          {
            '{0}': `${1 / 10 ** CUSTOM_PORTFOLIO_PRECISION}%`
          }
        ]);
      }
    }
  }

  return errors;
};

export const getAdvisoryTabResult = ({
  i18n,
  roboAdviceFormState,
  advisoryComponents,
  validateIfSavingPlanSuitable,
  isSessionInitialized,
  isFirstPartCompleted,
  isReadPdfReportPending,
  pageId,
  goals,
  assets,
  accounts,
  isTransactionListHidden,
  clientType,
  roboAdvice,
  recommendationItems,
  errors,
  isFollowUpFlow,
  amountForAdviceCurrentSum
}) => {
  const productPlatformReasoning = goals.map(g => {
    const goalRecommendationItems =
      recommendationItems.find(r => r.goalId === g.goalId)?.items || [];
    const goalNamespaceStatus = goalRecommendationItems.find(
      i => i.activeValue === g.data?.productPlatformNamespace
    )?.status;

    return (
      goalNamespaceStatus === NamespaceStatus.RECOMMENDED ||
      (!R.isNil(g.data?.productPlatformReasoning) &&
        g.data?.productPlatformReasoning !== '')
    );
  });
  const productPlatformNamespaces = Object.values(
    roboAdvice.productChooserRecommandation.namespaceConfig
  );
  const isProductChooserRecommandationEnabled =
    roboAdvice.productChooserRecommandation.enabled;
  const isProductChooserCommentRequired =
    roboAdvice.productChooserRecommandation.requireComment;

  const { values, errors: formErrors } = roboAdviceFormState;

  const isAssetClassAllocationAdvisorNotesEnabled =
    advisoryComponents.customPortfolio.assetClassAllocation.advisorNotes
      .enabled;
  const isAssetClassAllocationAdvisorNotesRequired =
    advisoryComponents.customPortfolio.assetClassAllocation.advisorNotes
      .required;
  const assetClassAllocationAdvisorNotesErrorsLength = Object.keys(
    formErrors?.customPortfolio?.assetClassAllocation?.advisorNotes || {}
  ).length;
  const isFundAllocationAdvisorNotesEnabled =
    advisoryComponents.customPortfolio.fundAllocation.advisorNotes.enabled;
  const isFundAllocationAdvisorNotesRequired =
    advisoryComponents.customPortfolio.fundAllocation.advisorNotes.required;
  const fundAllocationAdvisorNotesErrorsLength = Object.keys(
    formErrors?.customPortfolio?.fundAllocation?.advisorNotes || {}
  ).length;

  const advisoryTabProgress = R.pipe<
    (number | boolean)[],
    (number | boolean)[],
    (number | boolean)[],
    (number | boolean)[],
    (number | boolean)[],
    (number | boolean)[],
    (number | boolean)[]
  >(
    !isProductChooserRecommandationEnabled ||
      !isProductChooserCommentRequired ||
      productPlatformNamespaces.length < 2 ||
      productPlatformReasoning?.length === 0
      ? R.identity
      : R.append(productPlatformReasoning.every(p => p)),
    pageId === 'proposal'
      ? R.identity
      : R.append(
          R.isNil(
            getSavingsPlanValidationError({
              goals,
              validateIfSavingPlanSuitable,
              values,
              errors: formErrors,
              assets,
              clientType,
              accounts,
              isTransactionListHidden,
              isFollowUpFlow,
              amountForAdviceCurrentSum
            })
          )
        ),
    isAssetClassAllocationAdvisorNotesEnabled &&
      isAssetClassAllocationAdvisorNotesRequired &&
      goals.length
      ? R.append(
          goals
            .filter(({ data }) => data.isPortfolioCustom)
            .map(
              (_, index) =>
                index >= assetClassAllocationAdvisorNotesErrorsLength
            )
        )
      : R.identity,
    isFundAllocationAdvisorNotesEnabled &&
      isFundAllocationAdvisorNotesRequired &&
      goals.length
      ? R.append(
          goals
            .filter(({ data }) => data.isPortfolioCustom)
            .map((_, index) => index >= fundAllocationAdvisorNotesErrorsLength)
        )
      : R.identity,
    roboAdvice.targetMarketAssessmentEnabled &&
      roboAdvice.targetMarketAssessmentBlockNotSuitable
      ? R.append(
          errors.fundAllocationTargetMarketAssessment.map(error => !error)
        )
      : R.identity,
    R.flatten
  )([
    !getPortfolioValidationErrors(goals).length,
    R.isNil(getAdvisoryAdvisorNotesValidationError(values.advisoryAdvisorNotes))
  ]);

  return {
    value: RoboAdviceAdviceSessionPages.advisory,
    progress:
      R.pipe<(number | boolean)[], (number | boolean)[], number>(
        R.filter(R.identity<any>),
        R.length
      )(advisoryTabProgress) / R.length(advisoryTabProgress),
    disabled:
      isSessionInitialized &&
      (!isFirstPartCompleted ||
        isReadPdfReportPending ||
        !!formErrors.expectingFinancialChangesNote),
    isNavigationDisabled: isSessionInitialized && !isFirstPartCompleted,
    label: i18n('roboAdvice.tabs.advisory'),
    hasError: isSessionInitialized && getNumberOfErrors(errors) > 0
  };
};

export const useProposalTab = () => {
  const i18n = useI18n();
  const { pageId, clientType } = useParams<AdviceSessionParams>();
  const roboAdviceFormState = useRoboAdviceForm();
  const isSessionInitialized = useSessionStore(s => s.isSessionInitialized);
  const isFirstPartCompleted = useIsFirstPartCompleted();
  const { goals } = useGoalsStore();
  const isReadPdfReportPending = useProposalPageStore(
    s => s.isReadPdfReportPending
  );
  const {
    roboAdviceForm: {
      advisory: { validateIfSavingPlanSuitable },
      financialSituation: { assets, accounts }
    },
    roboAdvice,
    analyticsComponents: { isTransactionListHidden }
  } = useCustomerConfig();
  const readProductChooserRecommendation =
    useReadProductChooserRecommendation();
  const recommendationItems: {
    items: ReturnType<typeof readProductChooserRecommendation>['items'];
    goalId: string;
  }[] = [];
  for (const goal of goals) {
    const { items } = readProductChooserRecommendation({ goalId: goal.goalId });
    recommendationItems.push({ items, goalId: goal.goalId });
  }
  const errors = useGetAdvisoryErrors();
  const sessionStore = useSessionStore();
  const { isFollowUpEnabled } = useIsFollowUpEnabled();
  const { currentSum: amountForAdviceCurrentSum } = useAmountForAdviceValues();

  return getProposalTabResult({
    i18n,
    roboAdviceFormState,
    isSessionInitialized,
    isFirstPartCompleted,
    isReadPdfReportPending,
    pageId,
    goals,
    assets,
    accounts,
    clientType,
    validateIfSavingPlanSuitable,
    roboAdvice,
    recommendationItems,
    isTransactionListHidden,
    errors,
    isFollowUpFlow: !!sessionStore.followUpId && isFollowUpEnabled,
    amountForAdviceCurrentSum
  });
};

export const getProductPlatformError = (
  goals,
  roboAdvice,
  recommendationItems
) => {
  const productPlatformNamespaces = Object.values(
    roboAdvice.productChooserRecommandation.namespaceConfig
  );
  const productPlatformReasoning = goals.map(g => {
    const goalRecommendationItems =
      recommendationItems.find(r => r.goalId === g.goalId)?.items || [];
    const goalNamespaceStatus = goalRecommendationItems.find(
      i => i.activeValue === g.data?.productPlatformNamespace
    )?.status;

    return (
      goalNamespaceStatus !== NamespaceStatus.RECOMMENDED &&
      (R.isNil(g.data?.productPlatformReasoning) ||
        g.data?.productPlatformReasoning === '')
    );
  });
  const goalProductPlatformNamespacesNotFilled = goals
    .map(g => g.data?.productPlatformNamespace)
    .some(n => R.isNil(n));
  const isProductChooserRecommandationEnabled =
    roboAdvice.productChooserRecommandation.enabled;
  const isProductChooserCommentRequired =
    roboAdvice.productChooserRecommandation.requireComment;

  const areAllRequiredCommentsNotFilled =
    isProductChooserCommentRequired && productPlatformReasoning.some(p => p);

  if (
    isProductChooserRecommandationEnabled &&
    productPlatformNamespaces.length > 1 &&
    (goalProductPlatformNamespacesNotFilled || areAllRequiredCommentsNotFilled)
  ) {
    return 'roboAdvice.advisory.productPlatform.reasoningForSelectionPlaceholderError';
  }
  return undefined;
};

export const getProposalTabResult = ({
  i18n,
  roboAdviceFormState,
  isSessionInitialized,
  isFirstPartCompleted,
  isReadPdfReportPending,
  goals,
  pageId,
  assets,
  accounts,
  clientType,
  validateIfSavingPlanSuitable,
  roboAdvice,
  recommendationItems,
  isTransactionListHidden,
  errors,
  isFollowUpFlow,
  amountForAdviceCurrentSum
}) => {
  const { values, errors: formErrors } = roboAdviceFormState;
  const hasCustomPortfolioAdvisorNotesError = !!Object.keys(
    formErrors.customPortfolio?.assetClassAllocation?.advisorNotes ||
      formErrors.customPortfolio?.fundAllocation?.advisorNotes ||
      {}
  ).length;
  const fields =
    pageId === 'proposal'
      ? ['portfolio, advisoryAdvisorNotes']
      : ['portfolio', 'advisoryAdvisorNotes', 'savingsPlan'];
  const hasAnyError =
    R.pipe<string[], string[], boolean>(
      R.map(key => R.prop(key, formErrors)),
      R.any(R.complement(R.isNil))
    )(fields) ||
    getPortfolioValidationErrors(goals).length ||
    !R.isNil(
      getSavingsPlanValidationError({
        goals,
        validateIfSavingPlanSuitable,
        values,
        errors: formErrors,
        assets,
        clientType,
        accounts,
        isTransactionListHidden,
        isFollowUpFlow,
        amountForAdviceCurrentSum
      })
    ) ||
    !R.isNil(getProductPlatformError(goals, roboAdvice, recommendationItems)) ||
    hasCustomPortfolioAdvisorNotesError ||
    (roboAdvice.targetMarketAssessmentEnabled &&
      roboAdvice.targetMarketAssessmentBlockNotSuitable &&
      errors.fundAllocationTargetMarketAssessment.length > 0);

  return {
    value: RoboAdviceAdviceSessionPages.proposal,
    progress: 0,
    disabled:
      isSessionInitialized &&
      (!isFirstPartCompleted || hasAnyError || isReadPdfReportPending),
    isNavigationDisabled:
      isSessionInitialized && (!isFirstPartCompleted || hasAnyError),
    label: i18n('roboAdvice.tabs.proposal'),
    hasError: false
  };
};

export const useIsAdviceInformationCompleted = () => {
  const adviceInformation = useAdviceInformationTab();

  return adviceInformation.progress === 1;
};

export const useIsFirstPartCompleted = () => {
  const adviceInformation = useAdviceInformationTab();
  const purposeAndRiskTab = usePurposeAndRiskTab();
  const knowledgeAndExperienceTab = useKnowledgeAndExperienceTab();
  const financialSituationTab = useFinancialSituationTab();
  const sustainabilityTab = useSustainabilityTab();

  return (
    adviceInformation.progress === 1 &&
    purposeAndRiskTab.progress === 1 &&
    knowledgeAndExperienceTab.progress === 1 &&
    financialSituationTab.progress === 1 &&
    (!R.isNil(sustainabilityTab) ? sustainabilityTab.progress === 1 : true)
  );
};

export const useTabs = (): Tab[] => {
  const adviceInformationTab = useAdviceInformationTab();
  const purposeAndRiskTab = usePurposeAndRiskTab();
  const knowledgeAndExperienceTab = useKnowledgeAndExperienceTab();
  const financialSituationTab = useFinancialSituationTab();
  const sustainabilityTab = useSustainabilityTab();
  const advisoryTab = useAdvisoryTab();
  const proposalTab = useProposalTab();
  const {
    roboAdviceForm: {
      adviceInformation: { fields: adviceInformationFields = [] }
    },
    tenantSettings: { flowOrderAdviceInformation }
  } = useCustomerConfig();

  const firstTabs = [
    {
      tab: adviceInformationTab,
      order: flowOrderAdviceInformation.adviceInformation || 0,
      pageId: RoboAdviceAdviceSessionPages.adviceInformation
    },
    {
      tab: purposeAndRiskTab,
      order: flowOrderAdviceInformation.purposeAndRisk || 0,
      pageId: RoboAdviceAdviceSessionPages.purposeAndRisk
    },
    {
      tab: knowledgeAndExperienceTab,
      order: flowOrderAdviceInformation.knowledgeAndExperience || 0,
      pageId: RoboAdviceAdviceSessionPages.knowledgeAndExperience
    },
    {
      tab: financialSituationTab,
      order: flowOrderAdviceInformation.financialSituation || 0,
      pageId: RoboAdviceAdviceSessionPages.financialSituation
    },
    {
      tab: sustainabilityTab,
      order: flowOrderAdviceInformation.sustainability || 0,
      pageId: RoboAdviceAdviceSessionPages.sustainability
    }
  ].sort((a, b) => a.order - b.order);

  const endTabs = [
    {
      tab: advisoryTab,
      pageId: RoboAdviceAdviceSessionPages.advisory
    },
    {
      tab: proposalTab,
      pageId: RoboAdviceAdviceSessionPages.proposal
    }
  ];

  const tabs = [...firstTabs, ...endTabs].filter(({ tab }) => !!tab);

  return adviceInformationFields.length > 0
    ? tabs
    : tabs.filter(
        t => t.pageId !== RoboAdviceAdviceSessionPages.adviceInformation
      );
};

export const usePrevNextButtonsTabs = currentPageId => {
  const tabs = useTabs();

  const currentPageIndex = tabs.findIndex(t => t.pageId === currentPageId);

  if (currentPageIndex === -1) {
    return {
      prevPage: null,
      nextPage: null
    };
  }

  return {
    prevPage: currentPageIndex !== 0 ? tabs[currentPageIndex - 1] : null,
    nextPage:
      currentPageIndex !== tabs.length - 1 ? tabs[currentPageIndex + 1] : null
  };
};

export const useIsSessionInitialized = () => {
  const { adviceSessionId } = useParams<AdviceSessionParams>();
  const initializedSessionId = useSessionStore(s => s.initializedSessionId);

  return initializedSessionId === adviceSessionId;
};

export const useIsSessionReadOnly = () => {
  const status = useSessionStore(s => s.status);
  const isSessionInitialized = useIsSessionInitialized();

  return status === SessionStatuses.complete || !isSessionInitialized;
};
